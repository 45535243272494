import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Authenticator } from '../helper/Authenticator';
import Spinner from './Spinner';

interface ProtectedRouteProps {
  accessDenied?: boolean;
  to?: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  accessDenied = true,
  to = '/',
}) => {
  if (Authenticator.isLoading) {
    return <Spinner />;
  }
  if (accessDenied) {
    return <Navigate to={to} replace />;
  }
  return <Outlet />;
};

export default ProtectedRoute;
