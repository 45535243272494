import { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Spinner from '../utils/Spinner';
import { useTranslation } from 'react-i18next';
import { ErrorMessages, ErrorHandler } from '../helper/ErrorMessages';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import MenuIcon from '@mui/icons-material/Menu';
import { toast } from 'react-toastify';
import { Authenticator } from '../helper/Authenticator';
import avatars from '../utils/avatars';
import { clearAllData } from '../utils/indexedDB';
import { useFetchUser } from '../hooks/user.hooks';

const Sidebar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [avatarUrl, setAvatarUrl] = useState(avatars.memo_17); // Default avatar
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const errorMessages = new ErrorMessages();
  const settingsIcon = require('../assets/img/settings.png');
  const logoutIcon = require('../assets/img/logout.png');

  const {
    data: user,
    isError: isUserError,
    error: userError,
    isLoading: isUserLoading,
  } = useFetchUser();

  useEffect(() => {
    if (user?.Avatar) {
      // Extract the key from the full Avatar path
      const filenameMatch = user.Avatar.match(/memo_\d+/);
      const key = filenameMatch ? filenameMatch[0] : null;

      if (key && avatars[key]) {
        setAvatarUrl(avatars[key]);
      } else {
        setAvatarUrl(avatars.memo_17);
      }
    } else {
      setAvatarUrl(avatars.memo_17);
    }
  }, [user?.Avatar]);

  useEffect(() => {
    if (isUserLoading) {
      <Spinner />;
      return;
    }
  }, [isUserLoading]);

  useEffect(() => {
    if (isUserError) {
      ErrorHandler.handleError(userError, errorMessages, toast);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserError, userError]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        if (user) {
          //@ts-ignore
          Authenticator.setUserRoleType(user?.UserRoleType);
          //@ts-ignore
          Authenticator.setSubscriptionType(user?.SubscriptionType);
          localStorage.setItem('Currency', user?.Currency || 'Unknown');
        }
      } catch (error) {
        ErrorHandler.handleError(error, errorMessages, toast);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.UserRoleType, user?.Avatar]);

  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open);
  };

  const navigateTo = (path: string) => {
    navigate(path, { replace: true });
    if (isMobile) {
      setDrawerOpen(false);
    }
  };

  const logOutNavigate = () => {
    localStorage.clear();
    sessionStorage.clear();
    clearAllData()
      .then(() => {
        window.location.href = '/';
      })
      .catch((error) => {
        console.error('Error clearing IndexedDB:', error.message);
        window.location.href = '/';
      });
  };

  const sidebarContent = (
    <>
      <div className="logo"></div>
      <div className="user-info">
        {/* Displaying user avatar */}
        <Avatar
          className="user-avatar"
          src={avatarUrl}
          style={{ width: '100px', height: '100px' }}
        />

        {/* Displaying user details */}
        <div className="user-details">
          <Typography
            className="username"
            variant="subtitle1"
            style={{ textTransform: 'capitalize' }}
          >
            {user?.Username}
          </Typography>
          <Typography className="user-role" variant="body2">
            {t(user?.UserRoleType ? user.UserRoleType : '')}
          </Typography>
        </div>
      </div>
      {/* Sidebar menu items */}
      <List className="sidebar-menu">
        {!isLoading && user?.UserRoleType !== 'Tenant' && (
          <ListItemButton onClick={() => navigateTo('/overview')}>
            <ListItemText primary={t('overview')} />
            <div style={{ marginLeft: '10px', color: 'white' }}>
              <span className="material-symbols-outlined">dashboard</span>
            </div>
          </ListItemButton>
        )}

        {!isLoading && user?.UserRoleType !== 'Tenant' && (
          <ListItemButton onClick={() => navigateTo('/property')}>
            <ListItemText primary={t('properties')} />
            <div style={{ marginLeft: '10px', color: 'white' }}>
              <span className="material-symbols-outlined">
                real_estate_agent
              </span>
            </div>
          </ListItemButton>
        )}

        {!isLoading && user?.UserRoleType !== 'Tenant' && (
          <ListItemButton onClick={() => navigateTo('/tenant')}>
            <ListItemText primary={t('tenants')} />
            <div style={{ marginLeft: '10px', color: 'white' }}>
              <span className="material-symbols-outlined">reduce_capacity</span>
            </div>
          </ListItemButton>
        )}

        {/* Show Messages only if user role is NOT 'Caretaker' */}
        {!isLoading && user?.UserRoleType === 'Landlord' && (
          <ListItemButton onClick={() => navigateTo('/messages')}>
            <ListItemText primary={t('messages')} />
            <div style={{ marginLeft: '10px', color: 'white' }}>
              <span className="material-symbols-outlined">mail</span>
            </div>
          </ListItemButton>
        )}

        {/* Show Subscription only if user role is NOT 'Caretaker' */}
        {!isLoading && user?.UserRoleType === 'Landlord' && (
          <ListItemButton onClick={() => navigateTo('/plantype')}>
            <ListItemText primary={t('subscription')} />
            <div style={{ marginLeft: '10px', color: 'white' }}>
              <span className="material-symbols-outlined">card_membership</span>{' '}
            </div>
          </ListItemButton>
        )}

        {/* Show Requests only if user role is NOT 'Landlord' */}
        {!isLoading && user?.UserRoleType === 'Caretaker' && (
          <ListItemButton onClick={() => navigateTo('/requests')}>
            <ListItemText primary={t('request')} />
            <div style={{ marginLeft: '10px', color: 'white' }}>
              <span className="material-symbols-outlined">help</span>
            </div>
          </ListItemButton>
        )}
      </List>
      {/* Sidebar footer with settings and logout buttons */}
      <div className="sidebar-footer">
        <div className="settings-logout">
          {!isLoading && user?.UserRoleType !== 'Tenant' && (
            <Tooltip
              title={t('settings')}
              placement="top"
              PopperProps={{
                sx: {
                  '& .MuiTooltip-tooltip': {
                    maxWidth: '300px',
                    fontSize: '1rem',
                    padding: '8px 12px',
                  },
                },
              }}
            >
              <div
                className="settings-button menu-item"
                onClick={() => navigateTo('/profile')}
              >
                {/* Settings button to navigate to profile page */}
                <img
                  src={settingsIcon}
                  alt="Settings"
                  style={{ width: '20px', height: '20px' }}
                />
              </div>
            </Tooltip>
          )}

          <Tooltip
            title={t('logout')}
            placement="top"
            PopperProps={{
              sx: {
                '& .MuiTooltip-tooltip': {
                  maxWidth: '300px',
                  fontSize: '1rem',
                  padding: '8px 12px',
                },
              },
            }}
          >
            <div className="settings-button menu-item" onClick={logOutNavigate}>
              {/* Logout button to log out user */}
              <img
                src={logoutIcon}
                alt="Logout"
                style={{ width: '20px', height: '20px' }}
              />
            </div>
          </Tooltip>
        </div>
      </div>
    </>
  );

  return (
    <>
      {isMobile ? (
        <>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer(true)}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
            <div className="sidebar drawer-content">{sidebarContent}</div>
          </Drawer>
        </>
      ) : (
        <aside className="sidebar">{sidebarContent}</aside>
      )}
    </>
  );
};

export default Sidebar;
