const avatars: { [key: string]: string } = {
  memo_17: require('../assets/avatars/memo_17.png'),
  memo_3: require('../assets/avatars/memo_3.png'),
  memo_4: require('../assets/avatars/memo_4.png'),
  memo_5: require('../assets/avatars/memo_5.png'),
  memo_6: require('../assets/avatars/memo_6.png'),
  memo_7: require('../assets/avatars/memo_7.png'),
  memo_8: require('../assets/avatars/memo_8.png'),
  memo_9: require('../assets/avatars/memo_9.png'),
  memo_10: require('../assets/avatars/memo_10.png'),
  memo_11: require('../assets/avatars/memo_11.png'),
  memo_14: require('../assets/avatars/memo_14.png'),
  memo_16: require('../assets/avatars/memo_16.png'),
  memo_21: require('../assets/avatars/memo_21.png'),
  memo_26: require('../assets/avatars/memo_26.png'),
  memo_30: require('../assets/avatars/memo_30.png'),
};

export default avatars;
